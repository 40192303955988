'use client';

import { useState } from 'react';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { BRAZE_NO_PURCHASE_MODAL, BRAZE_OUT_OF_MARKET_MODAL } from 'braze';
import { getBrazeFlag } from 'harness-react';
import logger from 'logger';
import { getCookie } from 'cookies-next';
import {
  validEmail,
  GEOLOCATION_ALLOWED,
  GEOLOCATION_USA,
  GEOLOCATION_COMING_SOON,
  X_GEO_LOCATION_STATUS,
} from 'utils';
import { replaceUrls } from 'utils/urls';
import TextAndButtonVariant from './geolocationModal/textAndButtonVariant';
import TextAndButtonVariantRedirect from './geolocationModal/textAndButtonVariantRedirect';
import EmailEntryVariant from './geolocationModal/textfieldEntryVariant';

export default function GeolocationModal({
  content,
  postConsent,
  collectionPoint,
  consentPurposes,
  trackBrazeUser,
  createBrazeUser,
  styles = {},
  env,
}) {
  const geoLocationStatus = getCookie(X_GEO_LOCATION_STATUS) || GEOLOCATION_ALLOWED;
  const isUsaUser = geoLocationStatus === GEOLOCATION_USA;
  const isAllowed =
    geoLocationStatus === GEOLOCATION_COMING_SOON || geoLocationStatus === GEOLOCATION_ALLOWED;
  const signUpSource =
    geoLocationStatus === GEOLOCATION_COMING_SOON ? BRAZE_OUT_OF_MARKET_MODAL : BRAZE_NO_PURCHASE_MODAL;

  const [showModal, setShouldShowModal] = useState(geoLocationStatus !== GEOLOCATION_ALLOWED);

  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClose = () => {
    setIsEmailError(false);
    setIsError(false);
    setShouldShowModal(false);
  };

  const brazeEnabled = getBrazeFlag();
  const usaHomepageUrl = replaceUrls('https://www.hellolingo.com/', env, false);

  const onSignUp = async () => {
    if (!validEmail(email)) {
      setIsError(true);
      return;
    }

    if (email && brazeEnabled) {
      try {
        const externalId = await createBrazeUser(email, signUpSource);
        await trackBrazeUser(externalId);
        await postConsent(collectionPoint, consentPurposes, externalId);

        setIsSuccess(true);
      } catch (error) {
        logger.error(error, 'Unable to complete email modal');
        setIsError(true);
      }
    }
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
      onClose={handleClose}
      sx={{
        overflow: 'auto',
      }}
    >
      <Box
        width={{ xs: '100%', md: '600px' }}
        sx={{
          position: 'absolute',
          height: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '690px',
          outline: 'none',
          maxHeight: '100%',
        }}
      >
        <Box
          sx={{
            color: 'colorTheme.contentPrimaryOnLight',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'colorTheme.backgroundPrimaryLightWeb',
            boxShadow: 24,
            p: 4,
            outline: 0,
            padding: '27px 27px 40px 50px',
          }}
        >
          <Box
            sx={{
              alignSelf: 'flex-end',
              bgcolor: 'colorTheme.backgroundPrimaryLightWeb',
            }}
          >
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClose()}
              data-testid='closeModalButton'
            />
          </Box>
          <Box
            marginRight='23px'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              bgcolor: 'colorTheme.backgroundPrimaryLightWeb',
            }}
          >
            {/* TODO: Refactor and make easier to understand */}
            {isUsaUser && (
              <TextAndButtonVariantRedirect
                handleClose={handleClose}
                title={content.usaUsersModal.heading}
                subtitle={content.usaUsersModal.subtext}
                buttonText={content.usaUsersModal.buttonText}
                secondButtonText={content.usaUsersModal.secondButtonText}
                redirectUrl={usaHomepageUrl}
              />
            )}
            {!isSuccess && !isUsaUser && isAllowed && (
              <EmailEntryVariant
                title={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.title
                    : content.allowedUsersModal.title
                }
                subtitle={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.subtitle
                    : content.allowedUsersModal.subtitle
                }
                disclaimerText={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.disclaimerText.plaintext
                    : content.allowedUsersModal.disclaimerText.plaintext
                }
                buttonText={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.buttonText
                    : content.allowedUsersModal.buttonText
                }
                textfieldErrorMessage={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.textfieldErrorMessage
                    : content.allowedUsersModal.textfieldErrorMessage
                }
                setTextContent={setEmail}
                inputLabel={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.textfieldLabel
                    : content.allowedUsersModal.textfieldLabel
                }
                agreementLinks={
                  geoLocationStatus === GEOLOCATION_COMING_SOON
                    ? content.comingSoonUsersModal.agreementLinks
                    : content.allowedUsersModal.agreementLinks
                }
                onButtonCLick={onSignUp}
                isError={isEmailError}
                setIsError={setIsEmailError}
                isSubmitError={isError}
                styles={styles}
              />
            )}
            {!isAllowed && !isUsaUser && (
              <TextAndButtonVariant
                handleClose={handleClose}
                title={content.notAllowedUsersModal.heading}
                subtitle={content.notAllowedUsersModal.subtext}
                buttonText={content.notAllowedUsersModal.buttonText}
              />
            )}
            {isSuccess && (
              <TextAndButtonVariant
                handleClose={handleClose}
                title={content.successStateModal.heading}
                subtitle={content.successStateModal.subtext}
                buttonText={content.successStateModal.buttonText}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
